import moment from 'moment'
import { isEmpty } from 'lodash'
const apiEmerRestUrl = process.env.REACT_APP_API_EMER_REST_URL

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

const token = localStorage.getItem('token');

export const addEmergencia = async(values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEmerRestUrl}/emergencia/add`

	try {
		const resp = await fetch( url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(values),
			redirect: 'follow'
		})

		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const { cod_error } = await resp.json()

			if (cod_error === 9000) {
				return {
					message: 'Emergencia creada exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo crear la emergencia',
					status: 'NOK'
				}
			}
			
		} else {
			return {
				status: 'NOK',
			    message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch(err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para crear la emergencia`
		}
	}
}

export const getEmergenciasByFilters = async (values) => {
	const url = `${apiEmerRestUrl}/emergencia`

	let estado = '';
	if (values.estado === '0') {
		estado = null
	} else {
		estado = values.estado
	}

	let comuna = '';
	if (values.c_comuna === '0') {
		comuna = null
	} else {
		comuna = values.c_comuna
	}

	const raw = {
		"estado": isEmpty(values) ? null : estado,
		"fechaTomaInicio": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaInicio, 'DD-MM-YYYY').format("DDMMYYYY"),
		"fechaTomaFin": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaFin, 'DD-MM-YYYY').format("DDMMYYYY"),
		"c_comuna": isEmpty(values) ? null : comuna
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsEmergencia,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las emergencias`
		}
	}
}

const columnsEmergencia = [
	{ field: 'emergencia_id', headerName: 'ID Emergencia', width: 200 },
	{ field: 'id_sll', headerName: 'ID SLL', width: 200 },
	{ field: 'fecha_hora_crea', headerName: 'Fecha - Hora Creación', width: 250 },
	{ field: 'fecha_hora_trans', headerName: 'Fecha - Hora Transmisión', width: 250 },
	{ field: 'observacion', headerName: 'Observacion', width: 200 },
	{ field: 'comuna', headerName: 'Comuna', width: 200 },
	{ field: 'calle', headerName: 'Calle', width: 200 },
	{ field: 'numero', headerName: 'Número', width: 200 },
	{ field: 'depto', headerName: 'Depto', width: 200 },
	{ field: 'block', headerName: 'Block', width: 200 },
	{ field: 'casa', headerName: 'Casa', width: 200 },
	{ field: 'referencia', headerName: 'Referencia', width: 200 },
	{ field: 'telefono', headerName: 'Teléfono', width: 200 },
	{ field: 'nombre_cliente', headerName: 'Nombre Cliente', width: 200 },
	{ field: 'apellido_cliente', headerName: 'Apellido Cliente', width: 200 },
	{ field: 'estado', headerName: 'Estado', width: 150 },
	{ field: 'camion', headerName: 'Camión', width: 150, editable: true },
	{ field: 'producto', headerName: 'Producto', width: 200 },
	{ field: 'tipo_cliente', headerName: 'Tipo Cliente', width: 200 },
	{ field: 'canal', headerName: 'Canal', width: 200 },
	{ field: 'tipo', headerName: 'Tipo', width: 200 },
	{ field: 'elemento_falla', headerName: 'Elemento en Falla', width: 200 },
	{ field: 'tipo_servicio', headerName: 'Tipo Servicio', width: 200 },
	{ field: 'condicion', headerName: 'Condición', width: 200 },
	{ field: 'familia_producto', headerName: 'Familia del Producto', width: 250 },
	{ field: 'usuario_crea', headerName: 'Usuario Creación', width: 190 },
	{ field: 'usuario_trans', headerName: 'Usuario Transmisión', width: 210 },
]

export const getProductosByFamilia = async(familia) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEmerRestUrl}/emergencia/productoFamilia`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({ "familia": familia }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				productos: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los productos`
		}
	}
}

export const getElementosEnFalla = async() => {
	//const url = `${ apiUrl }/estado`
	const url = `${ apiEmerRestUrl }/desemer/elementoEnFalla`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				elementoEnFalla: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los Elementos en Falla`
		}
	}
}

export const getTipoServByElemEnFalla = async(numeros) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${ apiEmerRestUrl }/desemer/tipoServByElemEnFalla`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({ "numeros": numeros }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				tipoServicio: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los Tipos de Servicio`
		}
	}
}

export const getTiposServicio = async() => {
	//const url = `${ apiUrl }/estado`
	const url = `${ apiEmerRestUrl }/desemer/tiposServicio`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				tiposServicio: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los Tipos de Servicio`
		}
	}
}

export const getCondicion = async() => {
	//const url = `${ apiUrl }/estado`
	const url = `${ apiEmerRestUrl }/condicion`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				condicion: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los Tipos de Servicio`
		}
	}
}

export const getTipoEmerByElemFalla = async(elementoFalla) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEmerRestUrl}/configuracion/tipoEmerByElemFalla`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({ "elementoFalla": elementoFalla }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				data: data.data[0].para_valor,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los tipo de emergencias`
		}
	}
}

export const getTipoServByElemFalla = async(elementoFalla) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEmerRestUrl}/configuracion/tipoServByElemFalla`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({ "elementoFalla": elementoFalla }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				data: data.data[0].para_valor,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los tipo de servicio`
		}
	}
}

